import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './main/navbar/navbar.component';
import { HeroSectionComponent } from './pages/landingpage/hero-section/hero-section.component';
import { FeaturesComponent } from './pages/landingpage/features/features.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { GameComponent } from './pages/game/game.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeroSectionComponent,
    FeaturesComponent,
    LandingpageComponent,
    GameComponent,
    ComingSoonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
