<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top bg-dark" aria-label="Eighth navbar example">
    <div class="container">
        <a class="navbar-brand" routerLink=''>
            <img src="assets/images/main/sufftopia_banner.png" alt="" height="40"
                class="d-inline-block align-text-top"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbars"
            aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbars">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <!--  <li class="nav-item">
                                                                            <a class="nav-link active" aria-current="page" href="#">Home</a>
                                                                        </li>
                                                                        <li class="nav-item">
                                                                            <a class="nav-link" href="#">Link</a>
                                                                        </li>
                                                                        <li class="nav-item">
                                                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                                                                        </li>
                                                                        <li class="nav-item dropdown">
                                                                            <a class="nav-link dropdown-toggle" href="#" id="dropdown07" data-bs-toggle="dropdown"
                                                                                aria-expanded="false">Dropdown</a>
                                                                            <ul class="dropdown-menu" aria-labelledby="dropdown07">
                                                                                <li><a class="dropdown-item" href="#">Action</a></li>
                                                                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                                                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                                            </ul>
                                                                        </li> -->
            </ul>
            <span class="navbar-text">
                <b>Folge uns auf:&nbsp;</b>
            </span>
            <span class="navbar-text">
                <a href="https://instagram.com/sufftopia" target="_blank"><i
                        class="fab fa-instagram m-2 text-dark"></i></a>
            </span>
            <span class="navbar-text">
                <a href="https://www.facebook.com/sufftopia" target="_blank"><i
                        class="fab fa-facebook m-2 text-dark"></i></a>
            </span>
            <span class="navbar-text">
                <a href="https://www.tiktok.com/@sufftopia" target="_blank"><i
                        class="fab fa-tiktok m-2 text-dark"></i></a>
            </span>
            <!--  <input class="form-control" type="text" placeholder="Search" aria-label="Search"> -->

        </div>
    </div>
</nav>