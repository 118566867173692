<div class="container col px-4">
    <div class="row flex-lg-row-reverse align-items-center">

        <div class="col-lg-6 text-end">
            <h1 class="display-5 fw-bold lh-1 mb-3">Das erste Kartenspiel mit Datenbank</h1>
            <p class="lead">Ihr wollt als erste über den Produktstart informiert werden?
                Ihr wollt vom exclusiven Pre-Register Sparcode profitieren? </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Meldet euch jetzt an!</button>
            </div>
        </div>
        <div class="col-10 col-sm-8 col-lg-6">
            <img src="assets/landing_mockup.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700"
                height="500" loading="lazy">
        </div>
    </div>
</div>