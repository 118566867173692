import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeroSectionComponent } from './pages/landingpage/hero-section/hero-section.component';
import { NavbarComponent } from './main/navbar/navbar.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

const routes: Routes = [
  { path: '', component: ComingSoonComponent },
  /*  { path: '', redirectTo: '/first-component', pathMatch: 'full' }, // redirect to `first-component`
   { path: '**', component: NavbarComponent },  // Wildcard route for a 404 page */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
