<div class="container px-4 py-5 mt-0 pt-0" id="hanging-icons">

    <div class="p-5 mb-4 bg-white">
        <div class="container">
            <div class="row">

                <div class="col d-flex align-items-center p-1 border-right">
                    <div class="m-3">
                        <i class="fa-lg fas fa-user p-3"></i>
                    </div>
                    <div class="p-0 ps-2">
                        <h3 class="p-0">900+</h3>
                        <p class="m-0">Fragen</p>
                    </div>
                </div>
                <div class="col d-flex align-items-center p-1">
                    <div class="m-3">
                        <i class="fa-lg fas fa-layer-group p-3"></i>
                    </div>
                    <div class="p-0 ps-2">
                        <h3 class="p-0">6</h3>
                        <p class="m-0">Kategorien</p>
                    </div>
                </div>
                <div class="col d-flex align-items-center p-1">
                    <div class="m-3">
                        <i class="fa-lg fas fa-infinity p-3"></i>
                    </div>
                    <div class="p-0 ps-2">
                        <h3 class="p-0">500</h3>
                        <p class="m-0">Zufriedene Tester</p>
                    </div>
                </div>

            </div>


            <!--   <h1 class="display-5 fw-bold">Custom jumbotron</h1>
            <p class="col-md-8 fs-4">Using a series of utilities, you can create this jumbotron, just like the one in
                previous versions of Bootstrap. Check out the examples below for how you can remix and restyle it to
                your liking.</p>
            <button class="btn btn-primary btn-lg" type="button">Example button</button> -->
        </div>
    </div>



</div>